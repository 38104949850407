import './polyfills';
import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/layout";
import Main from "./pages/main";
import NoPage from "./pages/nopage";
import Profile from './pages/profile';
import { ToastContainer } from 'react-toastify';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import 'react-toastify/dist/ReactToastify.css';
import TgBotSetup from './pages/tg-bot-setup';
import JettonPage from './pages/jetton-page';
import Stars from './pages/stars';
import Lootboxes from './pages/lootboxes';


function App() {

  React.useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      const script = document.createElement('script');
      script.src = 'https://tganalytics.xyz/index.js';
      script.async = true;
      script.onload = () => {
        window.telegramAnalytics.init({
          token: 'eyJhcHBfbmFtZSI6ImJ1eWJhZyIsImFwcF91cmwiOiJodHRwczovL3QubWUvYnV5YmFndHJhZGluZ19ib3QiLCJhcHBfZG9tYWluIjoiaHR0cHM6Ly9idXliYWcubWUifQ==!EoL/NhF/9BwZUWY9XtgM9q53C9r1RwLLM6L7OUo3GWc=', // SDK Auth token, полученный через @DataChief_bot
          appName: 'buybag', // Идентификатор аналитики, который вы ввели в @DataChief_bot
        });
      };
      document.head.appendChild(script);
      
      // Удаляем скрипт при размонтировании компонента
      return () => {
        document.head.removeChild(script);
      };
    }
  }, []);

  return (
    <TonConnectUIProvider manifestUrl="https://raw.githubusercontent.com/buybagdev/wendonton/refs/heads/main/manifest.json">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Main />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/stars' element={<Stars />} />
            <Route path='/lootboxes' element={<Lootboxes />} />
            <Route path='/tg-bot-setup' element={<TgBotSetup />} />
            <Route path='/jetton/:jettonAddress' element={<JettonPage />} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </TonConnectUIProvider>

  );
}


export default App;
