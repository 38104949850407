import { cuttedWallet } from "../components/cuttedWallet";
import '../stars.css';
import Lottie from 'react-lottie';
import loadingAD from '../lotties/loading.json';
import tgstarAD from '../lotties/tgstar.json';
import { useUser } from "../components/user";
import React, { useState } from 'react';
import { toast, Bounce } from 'react-toastify';
import { UiService } from "../services/ui.service";
import { WithdrawStars } from '../components/withdraw-stars';

const Stars = () => {
  const [showModal, setShowModal] = useState(false);
  const [showWithdrawModal, setShowWithdrawModal] = useState(false);
  window.Telegram.WebApp.BackButton.show();
  const initData = window?.Telegram?.WebApp?.initData;
  const { user, isLoading, isError } = useUser(initData);
  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAD,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const tgstarOptions = {
    loop: true,
    autoplay: true,
    animationData: tgstarAD,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  if (isLoading || isError) {
    return (
      <div className="page">
        <Lottie
          options={loadingOptions}
          height={256}
          width={256}
          speed={1.5}
        />
      </div>
    );
  }

  function copyWallet() {
    navigator.clipboard.writeText(user.wallet);
    UiService.DisplayCopyToast();
  }

  function handleBuyStars() {
    window.Telegram.WebApp.openTelegramLink("https://t.me/buybagtrading_bot?start=addstars");
    window.Telegram.WebApp.close();
  }

  return (
    <div className="page stars-page">
      <div className="stars-header">
        <Lottie
          options={tgstarOptions}
          height={128}
          width={128}
          speed={0.75}
        />
        <div className="stars-title">bSTARS</div>
        <div className="stars-description">
          Deposit Stars to BuyBag<br />For spins, games, fires, and other chat activities
        </div>
      </div>

      <div className="stars-content">
        <div className="stars-balance-container">
          <div className="stars-balance">
            <Lottie
              options={{
                ...tgstarOptions,
                autoplay: false,
                loop: false
              }}
              height={24}
              width={24}
              isStopped={true}
              isPaused={true}
              initialSegment={[0, 1]}
              style={{ margin: 'unset' }}
            />
            <div className="stars-balance-amount">{user?.stars}</div>
          </div>
          <div className="stars-balance-label">your balance</div>
        </div>

        <button className="stars-button" onClick={handleBuyStars}>Buy More Stars</button>
        <a href="#" className="stars-gift-link" onClick={(e) => {
          e.preventDefault();
          setShowWithdrawModal(true);
        }}>Withdraw Stars</a>
      </div>

      <WithdrawStars
        starsBalance={user?.stars}
        shown={showWithdrawModal}
        close={() => setShowWithdrawModal(false)}
        minHeight={500}
      />
    </div>
  );
};

export default Stars;