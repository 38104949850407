import Lottie from 'react-lottie';
import rocketAD from '../lotties/rocket.json';
import lightningAD from '../lotties/lightprecomp.json';
import dollarAD from '../lotties/dollar.json';
import bluedollarAD from '../lotties/blue_dollar.json';
import loadingAD from '../lotties/loading.json';
import redgraphAD from '../lotties/redgraph.json';
import rollercoasterAD from '../lotties/rollercoaster.json';
import writinghandAD from '../lotties/writinghand.json';
import ribbonAD from '../lotties/ribbon.json';
import backhandAD from '../lotties/backhand.json';
import depositAD from '../lotties/deposit.json';
import wendAD from '../lotties/wend.json';
import fireAD from '../lotties/fire.json';
import spinAD from '../lotties/spin.json';
import { useUser } from "../components/user";
import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef } from 'react';
import { cuttedWallet } from '../components/cuttedWallet';
import { Swap } from '../components/swap';
import { Burn } from '../components/burn';
import { UiService } from '../services/ui.service';
import useSWR from 'swr';
import { useSWRConfig } from "swr";
import { ProfileApiService } from '../services/profile.api.service';
import { SendCoin } from "../components/send-coin";
import { BurnCoin } from "../components/burn-coin";
import { TonHelper } from '../helpers/ton-helper';
import { TokenList } from '../components/token-list';
import { LoadingContainer } from '../components/loadingContainer';
import { formattedNumber } from '../components/formattedNumber';
import { TonConnectCrisper } from '../components/ton-connect-crisper';
import { Link, useOutletContext } from 'react-router-dom';
import { AirdropProgress } from '../components/airdrop-progress';
import { Spin } from '../components/spin';
import { Deposit } from '../components/deposit';

const Main = forwardRef((props, ref) => {
  const [activeMenu, setActiveMenu] = useState('');
  const [pageInited, setPageInited] = useState(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [showBurnModal, setShowBurnModal] = useState(false);
  const [walletHint, setWalletHint] = useState('Tap to copy');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const userRef = useRef('');
  const { tonConnectRef } = useOutletContext();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const myFunction = () => {
    setActiveMenu("tonconnect");
  };

  useImperativeHandle(tonConnectRef, () => myFunction);

  const handleMenuItemClick = (item) => {
    switch (item) {
      case 'transfer_coins':
        setShowSendModal(true);
        break;
      case 'copy':
        copyWallet();
        break;
      case 'tonconnect':
        setActiveMenu("tonconnect");
        break;
      default:
        console.log(`Неизвестный пункт меню: ${item}`);
    }
    setIsMenuOpen(false);
  };

  const initData = window?.Telegram?.WebApp?.initData;
  const profileHookUrl = ProfileApiService.GetProfileHookUrl();
  const allWalletsHookUrl = ProfileApiService.GetAllWalletsHookUrl();

  const allWalletsFetcher = async (url) => {
    const accessToken = localStorage.getItem('bb_token');
    const headers = { 'Authorization': 'Bearer ' + accessToken };
    const response = await fetch(url, { headers });
    if (response.status !== 200) {
      return [];
    }
    return response.json();
  };

  const { user, isLoading, isError } = useUser(initData);
  const { data: allWalletsBalanceInfo, isLoading: isAllWalletsLoading, error: isAllWalletsError } = useSWR(allWalletsHookUrl, allWalletsFetcher, { refreshInterval: 30000, revalidateOnReconnect: false, revalidateOnFocus: false, revalidateIfStale: false, revalidateOnMount: true });
  const { mutate } = useSWRConfig();
  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAD,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


  useEffect(() => {
    let timeoutId;

    const updateBalances = async () => {
      if (user && user?.wallet && user?.wallet !== '' && pageInited === true) {
        try {
          let balanceUpdateModel = await updateBalance();

          mutate(profileHookUrl, { ...userRef.current, balance: balanceUpdateModel.data.balance }, { revalidate: balanceUpdateModel.revalidate });

        } catch (error) {
          console.error("Ошибка при обновлении баланса всех монет:", error);
        }
      }

      // Планируем следующее выполнение через 15 секунд
      timeoutId = setTimeout(updateBalances, 15000);
    };

    // Планируем первое выполнение через 15 секунд
    timeoutId = setTimeout(updateBalances, 15000);

    // Функция очистки
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [user?.wallet]);


  useEffect(() => {
    userRef.current = user;
  }, [user]);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  if (isLoading || isError) {
    return (
      <div className="page">
        <Lottie
          options={loadingOptions}
          height={256}
          width={256}
          speed={1.5}
        />
      </div>
    );
  }

  function copyRefLink() {
    navigator.clipboard.writeText(user.refLink);
    UiService.DisplayCopyToast();
  }

  function copyWallet() {
    navigator.clipboard.writeText(user.wallet);
    UiService.DisplayCopyToast();
    setWalletHint('Send TON');
  }

  async function updateBalance() {
    let response = await fetch(`https://toncenter.com/api/v3/account?address=${user?.wallet}`);
    if (response.status === 200) {
      let json = await response.json();
      if (json) {
        let balance = Number(json.balance);
        let tonBalance = (Math.floor(balance / 1000000000 * 100) / 100).toFixed(2);
        let balanceBeforeUpdate = user?.balance;
        let revalidate = false;
        try {
          revalidate = Number(balanceBeforeUpdate.replace(/,/g, '.')) < 0.5 && Number(tonBalance.replace(/,/g, '.')) >= 0.5;
        }
        catch (error) {
          console.log(error);
        }

        return { revalidate: revalidate, data: { ...user, balance: tonBalance } };
      }
    }
  }


  function myBagsText() {
    if (!allWalletsBalanceInfo || !allWalletsBalanceInfo.allWalletsBalance)
      return null;

    let sumInUsd = allWalletsBalanceInfo.allWalletsBalance
      .filter(x => x.rateUsd && x.balance > 0)
      .reduce((acc, x) => acc + (x.rateUsd * x.balance), 0);

    if (user.tonUsdRate > 0 && user?.balance > 0)
      sumInUsd += user.tonUsdRate * user.balance;

    /*     if (sumInUsd === 0)
          return null; */

    return `My Bags ≈ $${formattedNumber(sumInUsd)} `;
  }

  if (pageInited == false) {
    setPageInited(true);
    setTimeout(() => {
      let initialMenu = 'fastswap';

      const query = window?.Telegram?.WebApp?.initDataUnsafe?.start_param;
      if (query && query !== '') {
        switch (true) {
          case query.includes("spgfs"):
            initialMenu = 'fastswap';
            break;
          case query.includes("spgbn"):
            initialMenu = 'burn';
            break;
          case query.includes("spgspin"):
            initialMenu = 'spin';
            break;
          case query.includes("spgwd"):
            initialMenu = 'wend';
            break;
          case query.includes("spgtrad"):
            initialMenu = 'trading';
            break;
          case query.includes("spgcptrad"):
            initialMenu = 'copytrading';
            break;
          case query.includes("spgsnpr"):
            initialMenu = 'sniper';
            break;
          case query.includes("spgtrend"):
            initialMenu = 'trending';
            break;
          case query.includes("spgexwts"):
            initialMenu = 'tonconnect';
            break;
          default:
            break;
        }
      }

      setActiveMenu(initialMenu);
    }, 50);
  }

  function isEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  const rocketOptions = {
    loop: true,
    autoplay: true,
    animationData: rocketAD,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const lightningOptions = {
    loop: true,
    autoplay: true,
    animationData: lightningAD,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const dollarOptions = {
    loop: true,
    autoplay: true,
    animationData: dollarAD,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const bluedollarOptions = {
    loop: true,
    autoplay: true,
    animationData: bluedollarAD,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const wendOptions = {
    loop: true,
    autoplay: true,
    animationData: wendAD,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const ribbonOptions = {
    loop: true,
    autoplay: true,
    animationData: ribbonAD,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const backhandOptions = {
    loop: true,
    autoplay: true,
    animationData: backhandAD,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const redgraphOptions = {
    loop: true,
    autoplay: true,
    animationData: redgraphAD,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const rollercoasterOptions = {
    loop: true,
    autoplay: true,
    animationData: rollercoasterAD,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const writinghandOptions = {
    loop: true,
    autoplay: true,
    animationData: writinghandAD,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const fireOptions = {
    loop: true,
    autoplay: true,
    animationData: fireAD,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const spinOptions = {
    loop: true,
    autoplay: true,
    animationData: spinAD,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const depositOptions = {
    loop: true,
    autoplay: true,
    animationData: depositAD,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };


  function checkIfStringNumberZero(str) {
    return str === "0,00" || str === "0,000" || str === "0" || str === "0.00" || str === "0.000" || str === "0.0" || str === "0,0";
  }




  let onboardingCompleted = localStorage.getItem('_bb_onboarding_completed');
  if (onboardingCompleted !== true && onboardingCompleted !== "true") {
    return <div></div>;
  }

  return (
    <div className="page">
      <div className="wallet_info">
        <div className="wallet_title">
          <span className="wallet_title_text" onClick={() => copyWallet()}>{cuttedWallet(user.wallet)}</span>
          {checkIfStringNumberZero(user?.balance) && <div className="tap_to_copy">
            <Lottie
              options={backhandOptions}
              height={20}
              width={20}
            />
            <div>{walletHint}</div>
          </div>
          }
          {!checkIfStringNumberZero(user?.balance) &&
            <div className="wallet-menu-container" ref={menuRef}>
              {!isMenuOpen && <img className="caret_down mld" onClick={toggleMenu} src="/images/caret.png" />}
              <div className={`wallet_dropdown_menu ${isMenuOpen ? 'open' : ''}`}>
                <ul>
                  <li onClick={() => handleMenuItemClick('transfer_coins')}><img src="/images/transfer.png" /><span>Transfer Coins</span></li>
                  {/* <li onClick={() => handleMenuItemClick('tonconnect')}><img src="/images/transfer.png" /><span>Connect Wallet</span></li> */}
                  <li onClick={() => handleMenuItemClick('copy')}><img src="/images/menu_copy.png" /><span>Copy</span></li>

                  {/* Добавьте дополнительные пункты меню по необходимости */}
                </ul>
              </div>
            </div>
          }

          {user?.stars > 0 && (
            <div className="wallet_secondary">
              <Link to="/stars">
                <div className="stars_badge">
                  {user.stars}
                  <svg viewBox="0 0 24 24">
                    <path d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z" />
                  </svg>
                </div>
              </Link>
            </div>
          )}

          {/* Добавляем иконку лутбоксов */}
          <div className="wallet_secondary" style={{ marginLeft: "10px" }}>
            <Link to="/lootboxes">
              <div className="lootbox_badge">
                <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.5777 3.38197L17.5777 4.43152C19.7294 5.56066 20.8052 6.12523 21.4026 7.13974C22 8.15425 22 9.41667 22 11.9415V12.0585C22 14.5833 22 15.8458 21.4026 16.8603C20.8052 17.8748 19.7294 18.4393 17.5777 19.5685L15.5777 20.618C13.8221 21.5393 12.9443 22 12 22C11.0557 22 10.1779 21.5393 8.42229 20.618L6.42229 19.5685C4.27063 18.4393 3.19479 17.8748 2.5974 16.8603C2 15.8458 2 14.5833 2 12.0585V11.9415C2 9.41667 2 8.15425 2.5974 7.13974C3.19479 6.12523 4.27063 5.56066 6.42229 4.43152L8.42229 3.38197C10.1779 2.46066 11.0557 2 12 2C12.9443 2 13.8221 2.46066 15.5777 3.38197Z" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" />
                  <path d="M21 7.5L12 12M12 12L3 7.5M12 12V21.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round" />
                </svg>
              </div>
            </Link>
          </div>
        </div>

        <div className="wallet_balance">
          <div className="wallet_balance_title">
            {user?.balance}
            <img width={32} height={32} src="/images/ton_icon.png" />
          </div>
        </div>


        <LoadingContainer width="160px" height="20px" textClassName={"default_grey_text"} text={myBagsText()}></LoadingContainer>
      </div>
      <div className="menu">
        <div className={"menu_item" + (activeMenu === 'fastswap' ? ' active' : '')} onClick={() => setActiveMenu('fastswap')}>
          <div className='menu_item_icon'>
            <Lottie
              speed={activeMenu == "fastswap" ? 1 : 0}
              options={rocketOptions}
              height={20}
              width={20}
            />
          </div>
          <span className='menu_item_title'>Fast Swap</span>
        </div>
        <div className={"menu_item" + (activeMenu === 'tonconnect' ? ' active' : '')} onClick={() => setActiveMenu('tonconnect')}> <div className='menu_item_icon'>
          <Lottie
            speed={activeMenu == 'tonconnect' ? 1 : 0}
            options={depositOptions}
            height={20}
            width={20}
          />
        </div>
          <span className='menu_item_title'>Deposit</span>
        </div>
        <div className={"menu_item" + (activeMenu === 'spin' ? ' active' : '')} onClick={() => setActiveMenu('spin')}>
          <div className='menu_item_icon'>
            <Lottie
              speed={activeMenu == "spin" ? 1 : 0}
              options={spinOptions}
              height={20}
              width={20}
            />
          </div>
          <span className='menu_item_title'>Turbo</span>
        </div>

        <div className={"menu_item" + (activeMenu === 'burn' ? ' active' : '')} onClick={() => setActiveMenu('burn')}>
          <div className='menu_item_icon'>
            <Lottie
              speed={activeMenu == "burn" ? 1 : 0}
              options={fireOptions}
              height={20}
              width={20}
            />
          </div>
          <span className='menu_item_title'>Burn</span>
        </div>

        <div className={"menu_item" + (activeMenu === 'wend' ? ' active' : '')} onClick={() => setActiveMenu('wend')}>
          <div className='menu_item_icon'>
            <Lottie
              speed={activeMenu == "wend" ? 1 : 0}
              options={dollarOptions}
              height={20}
              width={20}
            />
          </div>
          <span className='menu_item_title' style={{ marginLeft: "2px" }}>WEND</span>
        </div>
        <div className={"menu_item" + (activeMenu === 'trading' ? ' active' : '')} onClick={() => setActiveMenu('trading')}>
          <div className='menu_item_icon'>
            <Lottie
              speed={activeMenu === 'trading' ? 1 : 0}
              options={redgraphOptions}
              height={20}
              width={20}
            />
          </div>
          <span className='menu_item_title'>Trading</span>
        </div>
        <div className={"menu_item" + (activeMenu === 'copytrading' ? ' active' : '')} onClick={() => setActiveMenu('copytrading')}>    <div className='menu_item_icon'>
          <Lottie
            speed={activeMenu === 'copytrading' ? 1 : 0}
            options={writinghandOptions}
            height={20}
            width={20}
          />
        </div>
          <span className='menu_item_title'>CopyTrading</span></div>
        <div className={"menu_item" + (activeMenu === 'trending' ? ' active' : '')} onClick={() => setActiveMenu('trending')}> <div className='menu_item_icon'>
          <Lottie
            speed={activeMenu == 'trending' ? 1 : 0}
            options={rollercoasterOptions}
            height={20}
            width={20}
          />
        </div>
          <span className='menu_item_title'>Trends</span></div>
        <div className={"menu_item" + (activeMenu === 'sniper' ? ' active' : '')} onClick={() => setActiveMenu('sniper')}> <div className='menu_item_icon'>
          <Lottie
            speed={activeMenu == 'sniper' ? 1 : 0}
            options={ribbonOptions}
            height={20}
            width={20}
          />
        </div>
          <span className='menu_item_title'>Sniper</span>
        </div>
      </div>
      {activeMenu === 'fastswap' &&
        <Swap tonBalance={user?.balance} allCoinsBalances={allWalletsBalanceInfo?.allWalletsBalance ?? []} userSlippage={user?.slippage} userMevProtection={user?.mevProtection}></Swap>
      }
      {activeMenu === 'burn' &&
        <Burn tonBalance={user?.balance} allCoinsBalances={allWalletsBalanceInfo?.allWalletsBalance ?? []}></Burn>
      }
      {activeMenu === 'spin' &&
        <Spin user={user} allWalletsBalance={allWalletsBalanceInfo?.allWalletsBalance ?? []}></Spin>
      }

      {activeMenu === 'wend' &&
        <div className='menu_content'>
          <div className='menu_content_title'> <Lottie
            options={wendOptions}
            style={{ margin: "unset", zIndex: 1 }}
            width={100}
          />💸 BuyBag is the First Trading Bot fueled by Wen Dizel, the legend of «Fud & Furious» 🏎️💨 <br />
            <div className="regularText mtd">$WEND is a meme nitro used in this bot<br />$WEND is a vibrant lights under fast cars <br />$WEND is a family! <br /></div>
            <div className="regularText mtd">Don't wait! Grab your $WEND now! It's time to shift gears and ride the bull! 🐂💨</div>
          </div>
        </div>
      }
      {activeMenu === 'trading' &&
        <div className='menu_content'><div className='menu_content_title'>Coming Soon... <br /><div className="regularText mtd">⛽️ Gas Pump & DeDust. Execute buy and sell orders an easy-to-use platform with real-time charts and on-chain analysis tools.</div></div></div>
      }
      {activeMenu === 'copytrading' &&
        <div className='menu_content'><div className='menu_content_title'>Coming Soon... <br /><div className="regularText mtd">Automatically copy trades from any wallet on the TON blockchain. With flexible strategies and smart filters.</div> </div></div>
      }
      {activeMenu === 'trending' &&
        <div className='menu_content'><div className='menu_content_title'>Coming Soon... <br /><div className="regularText mtd">Spot emerging trends and potential gems.</div>
        </div>
        </div>
      }

      {activeMenu === 'sniper' &&
        <div className='menu_content'><div className='menu_content_title'>Coming Soon... <br /><div className="regularText mtd">Rapid execution of targeted trades.</div> </div></div>
      }
      {activeMenu === 'tonconnect' &&
        <div className='menu_content menu_content_ext_wallets'>
          <Deposit user={user} allWalletsBalance={allWalletsBalanceInfo?.allWalletsBalance ?? []} mainWallet={user?.wallet}></Deposit>
        </div>
      }

      <TokenList user={user} allWalletsBalance={allWalletsBalanceInfo?.allWalletsBalance ?? []}></TokenList>


      <AirdropProgress></AirdropProgress>
      {/*    <div className="airdrop_info">
        <div className="df js">
          <Lottie
            options={lightningOptions}
            height={20}
            style={{ margin: "unset", zIndex: 1 }}
            width={20}
          /><span className="airdrop_text">Airdrop Confirmed</span></div>
        <ol className='airdrop_list'>
          <li>Deposit TON<br />
            <span onClick={() => copyWallet()}>{cuttedWallet(user.wallet)}</span></li>
          <li> Swap at least 1 TON to $WEND</li>
          <li>Hold $WEND in this wallet before snapshot</li>
        </ol>
        <div className="semibold text_white">Early $WEND holders get special rewards!</div>
        <div className="df js mt12"><img width={24} height={24} src="/images/money_mouth_face.png" /><span className="regularText mld">0% comission for all trades</span></div>
        <div className="df js mt12"><img width={24} height={24} src="/images/crystal_ball.png" /><span className="regularText mld">Mystery Loot</span></div>
        <div className="semibold text_white mt12">Refer your frens and earn cash. No f**king points, no crystals! 💸</div>
      </div>
 */}
      <div className="settings_crisper_header">YOUR REFERRAL LINK</div>
      <div className="settings_crisper mt4">
        <div className="settings_ref_link" onClick={() => copyRefLink()} >
          <div>{user?.refLink ? user.refLink.substring(0, 39) + '...' : ''}</div><img width={24} height={24} src="/images/copy2.png" />
        </div>
        <div onClick={() => copyRefLink()} className="settings_share_btn">Copy</div>
      </div>



      <div className="semibold text_white mt12 pl12">Your current referrals: {user?.refs?.length ?? 0}</div>
      {user?.refs?.length > 0 && user.refs.map(ref =>
        <div key={ref} className="normal_text text_white mtd pl12">@{ref}</div>
      )}
      <br />

      {/* <div className='ref_btn'>
        <img width={24} height={24} src="/images/reficon.png" />
        <div className='ref_btn_text'>https://t.me/+XsHy-jpdzds2Mjli</div>
      </div> */}

      <SendCoin minHeight={500} shown={showSendModal} allWalletsBalance={allWalletsBalanceInfo?.allWalletsBalance ?? []} tonBalance={user?.balance} userWallet={user?.wallet} close={() => {
        setShowSendModal(false);
      }} />

      <BurnCoin minHeight={500} shown={showBurnModal} allWalletsBalance={allWalletsBalanceInfo?.allWalletsBalance ?? []} tonBalance={user?.balance} userWallet={user?.wallet} close={() => {
        setShowBurnModal(false);
      }} />

    </div>
  );
});

export default Main;