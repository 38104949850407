
import React, { useEffect, useState, useRef } from 'react';
import { TonHelper } from '../helpers/ton-helper';
import { UiService } from '../services/ui.service';
import { cuttedWallet } from "../components/cuttedWallet";
import { ActionApiService } from '../services/action.api.service';
import { toast, Bounce } from 'react-toastify';
import { LocalStorageHelper } from '../helpers/local-storage-helper';
import Lottie from 'react-lottie';
import loadingAD from '../lotties/loading.json';
import {formattedNumber} from './formattedNumber';


export function SendCoin({ allWalletsBalance, tonBalance, userWallet, children, shown, close, minHeight }) {
    const [activeStep, setActiveStep] = useState(1);
    const [selectedAsset, setSelectedAsset] = useState(null);
    const [receiverAddress, setReceiverAddress] = useState(null);
    const [receiverAddressValid, setReceiverAddressValid] = useState(false);
    const [sendAmount, setSendAmount] = useState(0);
    const [sendAmountValid, setSendAmountValid] = useState(false);
    const [sendAmountErrorMessage, setSendAmountErrorMessage] = useState(null);
    const [selectedAssetMetadata, setSelectedAssetMetadata] = useState(null);
    const [amountInputWidth, setAmountInputWidth] = useState(40);
    const [controlsDisabled, setControlsDisabled] = useState(false);
    const amount_input_content = useRef();


    let recentAddresses = LocalStorageHelper.GetRecentTonAddresses();

    function closeAndReset() {
        setActiveStep(1);
        setSelectedAssetMetadata(tonMetadata);
        setSelectedAsset(null);
        setReceiverAddress(null);
        setReceiverAddressValid(false);
        setSendAmount(0);
        setSendAmountValid(false);
        setSendAmountErrorMessage(null)

        close();
    }

    let tonBalanceNumber = Number(tonBalance.replace(/,/g, '.'));
    let tonMetadata = { shortTitle: 'TON', title: 'Toncoin', image: 'https://assets.dedust.io/images/ton.webp', balance: tonBalanceNumber };

    function nextStep() {
        if (activeStep === 1) {
            setReceiverAddress(null);
            setReceiverAddressValid(false);
        }

        if (activeStep === 2) {
            setAmountInputWidth(40);
            setSendAmount(0);
            setSendAmountErrorMessage(null);
            setSendAmountValid(false);
        }

        setActiveStep(activeStep + 1);
    }

    useEffect(() => {
        if (shown) {
          document.body.classList.add('modal-open');
        } else {
          document.body.classList.remove('modal-open');
        }
      
        return () => {
          document.body.classList.remove('modal-open');
        };
      }, [shown]);

    useEffect(
        () => {
            if (selectedAsset === null) {
                setSelectedAssetMetadata(tonMetadata);
            }
            else {
                setSelectedAssetMetadata(allWalletsBalance.find(x => x.address == selectedAsset));
            }
        }, [selectedAsset]);

    useEffect(() => {
        if (amount_input_content.current)
            setAmountInputWidth(amount_input_content.current.offsetWidth + 8);

        if(!sendAmount || sendAmount === '') {
            setSendAmountValid(false);
        }

        if (sendAmount && sendAmount != '' && selectedAssetMetadata) {
            let sendAmountNumber = Number(removeLeadingZerosRegex(sendAmount.replace(/,/g, '.')));
            let isValid = true;
            let errorMessage = '';

            if (selectedAssetMetadata.shortTitle === "TON") {
                if (tonBalanceNumber < sendAmountNumber) {
                    isValid = false;
                    errorMessage = "Insufficient balance";
                }
            }
            else {
                if (selectedAssetMetadata.balance < sendAmountNumber) {
                    isValid = false;
                    errorMessage = "Insufficient balance";
                }
                else {
                    if (tonBalanceNumber < 0.07) {
                        isValid = false;
                        errorMessage = "Insufficient funds to pay 0.07 TON fee";
                    }
                }
            }

            setSendAmountValid(isValid);
            setSendAmountErrorMessage(errorMessage);
        }

    }, [sendAmount]);


    if (shown === false) {
        return null;
    }

    function confirmAndSend() {
        setControlsDisabled(true);
        let address = receiverAddress;
        ActionApiService.Send(sendAmount, selectedAssetMetadata?.address ?? "TON", receiverAddress).then(result => {
            if (result.isSuccess === true) {
                toast.success('Sent!', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
                LocalStorageHelper.AddTonAddressToRecent(address);
                closeAndReset();
            }
            else {
                toast.error(result.message ?? 'Failed.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
            }

            setControlsDisabled(false);
        }).catch((error) => {
            setControlsDisabled(false);
        });
    }

    function receiverAddressChanged(address) {
        setReceiverAddress(address);
        let convertedAddress = TonHelper.GetBounceableAddressOrNull(address);

        setReceiverAddressValid(convertedAddress !== null);
    }

    function sendAmountChanged(sendAmount) {
        setSendAmount(sendAmount);
    }

    function setReceiverAddressManually(address) {
        receiverAddressChanged(address);
        setActiveStep(3);
    }

    function assetClick(assetAddress) {
        if (allWalletsBalance.some(x => x.address == assetAddress && x.balance > 0) || assetAddress === null && tonBalanceNumber > 0) {
            setSelectedAsset(assetAddress);
            nextStep();
        }
    }

    async function pasteAddress() {

        const text = await navigator.clipboard.readText();
        receiverAddressChanged(text);
    }

    function removeLeadingZerosRegex(str) {
        if (!str)
            return '';

        return str.replace(/^0+(?=\d)/, '');
    }

    const loadingOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAD,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    


    return (
        <div className={'modal_container visible'}>
             <div onClick={() => { closeAndReset() }} className='modal_overlay_half'></div>
        <div className='bottom_stuck_modal half_stuck_modal modal_scrollable' style={{minHeight: minHeight}}>
            <div className='bottom_stuck_modal_content pdside16'>
                {activeStep > 1 && <div onClick={() => activeStep == 1 ? close() : setActiveStep(activeStep - 1)} className="close_btn mtl"><img src="/images/caret.png" /></div>}
                {activeStep === 1 &&
                    <>
                    <div className='mtxl'></div>
                        <div className='popup_title'>
                            Select asset to transfer
                        </div>
                        <div className="settings_coin_balances">
                            <div className={'asset_item_plain ' + (selectedAsset === null ? "" : "")} onClick={() => assetClick(null)}>
                                <img className='asset_item_image' src="/images/toniconfill.png" />
                                <div className='asset_desc'>
                                    <div className='asset_short_title'>TON</div>
                                    <div className='asset_title'>Toncoin</div>
                                </div>
                                <div className='asset_balance_desc'>
                                    <div className='asset_balance'>{formattedNumber(tonBalanceNumber ?? 0)}</div>
                                    {/* <div className='asset_usd_balance'>$0</div> */}
                                </div>
                            </div>
                            {allWalletsBalance != null && allWalletsBalance.length > 0 && allWalletsBalance.filter(coin => coin.balance > 0.0009).sort((a, b) => b.balance - a.balance).map((coin, index) =>
                                <div className={'asset_item_plain ' + (selectedAsset === coin?.address ? "" : "")} key={index} onClick={() => assetClick(coin?.address)}>
                                    <img className='asset_item_image' src={coin?.image} />
                                    <div className='asset_desc'>
                                        <div className='asset_short_title'>{coin?.shortTitle}</div>
                                        <div className='asset_title'>{coin?.title}</div>
                                    </div>
                                    <div className='asset_balance_desc'>
                                        <div className='asset_balance'>{formattedNumber(coin?.balance ?? 0)}</div>
                                        {/* <div className='asset_usd_balance'>$0</div> */}
                                    </div>
                                </div>
                            )}
                        </div>
                        {/* <div className=""><div onClick={() => nextStep()} className={"onboarding_next_btn " + (allWalletsBalance.some(x => x.address == selectedAsset && x.balance > 0) || selectedAsset === null && tonBalanceNumber > 0 ? "" : "unclickable")}>Continue</div></div> */}
                    </>
                }
                {activeStep === 2 &&
                    <>
                        <div className='receiver_address_input_container'>
                            <input type="text" autoFocus className={"default_input  address_input " + (receiverAddressValid === true ? "input_valid" : "")} placeholder='TON Address' value={receiverAddress || ''} onChange={event => receiverAddressChanged(event.target.value)} onPaste={event => receiverAddressChanged(event.target.value)} />
                            {/* {(!receiverAddress || receiverAddress === '') && <span onClick={() => pasteAddress()} className='paste_btn'>Paste</span>} */}
                        </div>

                        {recentAddresses && recentAddresses.length > 0 &&
                            <div className='recent_addresses'>
                                <div className='recent_addresses_title'>Recent:</div>
                                {recentAddresses.map(x =>
                                    <div key={x.address} className='recent_address_crisper' onClick={() => setReceiverAddressManually(x.address)}>
                                        <img src={TonHelper.TonImageAsset()} className='recent_address_asset_image' />
                                        <div className='recent_address_asset_desc'>
                                            <div className='recent_address_asset_title'>{cuttedWallet(x.address)}</div>
                                            <div className='recent_address_asset_value'>TON address</div>
                                        </div>
                                    </div>)
                                }
                            </div>
                        }

                        <div className=""><div onClick={() => nextStep()} className={"default_next_btn btn_red " + (receiverAddressValid === true ? "" : "unclickable")}>Continue</div></div>
                    </>
                }
                {activeStep === 3 &&
                    <>
                        <div className={'amount_input_crisper ' + (sendAmountErrorMessage && sendAmountErrorMessage !== '' ? 'error' : '')}>
                            <span className="amount_input_content" ref={amount_input_content}>{!sendAmount || sendAmount === '' ? <span>&nbsp;&nbsp;&nbsp;&nbsp;</span> : sendAmount}</span>
                            <input inputMode="decimal" pattern="[0-9]*" autoFocus type="number" style={{ width: amountInputWidth }} className="default_input  amount_input" value={sendAmount} onChange={event => sendAmountChanged(removeLeadingZerosRegex(event.target.value))} />
                            <span className='amount_input_suffix'>{selectedAssetMetadata?.shortTitle}</span>
                        </div>
                        {sendAmountErrorMessage && sendAmountErrorMessage !== '' &&
                            <div className='amount_input_error_message'>{sendAmountErrorMessage}</div>
                        }
                        <div className='from_balance_crisper'>
                            <img src={selectedAssetMetadata?.image ?? TonHelper.NoImageAsset()} className='from_balance_asset_image' />
                            <div className='from_balance_asset_desc'>
                                <div className='from_balance_asset_title'>From Balance</div>
                                <div className='from_balance_asset_value'>{selectedAssetMetadata?.shortTitle === "TON" ? tonBalanceNumber + " TON" : selectedAssetMetadata?.balance + " " + selectedAssetMetadata?.shortTitle}</div>
                            </div>
                        </div>

                        <div className=""><div onClick={() => nextStep()} style={{ marginTop: 12 }} className={"default_next_btn btn_red " + (sendAmountValid === true ? "" : "unclickable")}>Continue</div></div>
                    </>
                }

                {activeStep === 4 &&
                    <>
                        {controlsDisabled === true && <Lottie
                            options={loadingOptions}
                            height={200}
                            width={200}
                            speed={1.5}
                        />}
                        {controlsDisabled === false && selectedAssetMetadata?.image &&
                            <>
                                <div className="asset_logo_crisper">
                                    <img src={selectedAssetMetadata?.image} />
                                </div>
                                <div className='confirm_send_title'>Confirm action</div>
                                <div className='confirm_send_subtitle'>Transfer {selectedAssetMetadata?.shortTitle}</div>
                            </>
                        }

                        <div>
                            <div className="plain_badge">
                                <div className='plain_badge_title'>
                                    Sender
                                </div>
                                <div className='plain_badge_value'>
                                    {cuttedWallet(userWallet)}
                                </div>
                            </div>
                            <div className="plain_badge">
                                <div className='plain_badge_title'>
                                    Destination
                                </div>
                                <div className='plain_badge_value'>
                                    {cuttedWallet(receiverAddress)}
                                </div>
                            </div>
                            <div className="plain_badge">
                                <div className='plain_badge_title'>
                                    Amount
                                </div>
                                <div className='plain_badge_value'>
                                    {sendAmount + ' ' + selectedAssetMetadata?.shortTitle}
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div onClick={() => confirmAndSend()} className={"default_next_btn btn_red " + (sendAmountValid === true && controlsDisabled === false ? "" : "unclickable")}>
                                Confirm and Transfer
                            </div>
                        </div>
                    </>
                }

            </div>

        </div>
        </div>
    );


}
