import React, { useState, useEffect } from 'react';
import '../lootboxes.css';
import Lottie from 'react-lottie';
import loadingAD from '../lotties/loading.json';
import { useUser } from "../components/user";
import { LootboxApiService } from '../services/lootbox.api.service';
import { LootboxTaskService } from '../services/lootbox.task.api.service';
import { toast, Bounce } from 'react-toastify';
import { UiService } from '../services/ui.service';

const Lootboxes = () => {
  window.Telegram.WebApp.BackButton.show();
  const initData = window?.Telegram?.WebApp?.initData;
  const { user, isLoading: isUserLoading, isError: isUserError } = useUser(initData);

  const [lootboxes, setLootboxes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Удаляем mockTasks и добавляем новые состояния
  const [tasks, setTasks] = useState([]);
  const [isTasksLoading, setIsTasksLoading] = useState(true);
  const [claimingTaskId, setClaimingTaskId] = useState(null);

  // Определяем функцию loadTasks перед использованием в useEffect
  const loadTasks = async () => {
    try {
      setIsTasksLoading(true);
      const tasksData = await LootboxTaskService.GetUserTasks();
      setTasks(tasksData);
    } catch (err) {
      console.error('Error loading tasks:', err);
      setError('Failed to load tasks');
    } finally {
      setIsTasksLoading(false);
    }
  };

  // Определяем функцию loadLootboxes перед использованием в useEffect
  const loadLootboxes = async () => {
    try {
      const lootboxesData = await LootboxApiService.GetLootboxes();
      // Обрабатываем данные лутбоксов
      const rarityOrder = {
        'common': 1,
        'rare': 2,
        'epic': 3,
        'legendary': 4
      };

      const sortedData = [...lootboxesData].sort((a, b) => {
        const rarityA = rarityOrder[a.rarity.toLowerCase()] || 999;
        const rarityB = rarityOrder[b.rarity.toLowerCase()] || 999;
        return rarityA - rarityB;
      });

      const lootboxesWithColor = sortedData.map(lootbox => ({
        ...lootbox,
        color: getRarityColor(lootbox.rarity)
      }));

      setLootboxes(lootboxesWithColor);
    } catch (err) {
      console.error('Error loading lootboxes:', err);
      setError('Failed to load lootboxes');
    } finally {
    
    }
  };

  // Объединяем оба useEffect в один
  useEffect(() => {
    const fetchData = async () => {
      if (!isUserLoading && !isUserError) {
        try {
          setIsLoading(true);
          setIsTasksLoading(true);

          // Запускаем оба запроса параллельно
          const [lootboxesData, tasksData] = await Promise.all([
            LootboxApiService.GetLootboxes(),
            LootboxTaskService.GetUserTasks()
          ]);

          // Обрабатываем данные лутбоксов
          const rarityOrder = {
            'common': 1,
            'rare': 2,
            'epic': 3,
            'legendary': 4
          };

          const sortedData = [...lootboxesData].sort((a, b) => {
            const rarityA = rarityOrder[a.rarity.toLowerCase()] || 999;
            const rarityB = rarityOrder[b.rarity.toLowerCase()] || 999;
            return rarityA - rarityB;
          });

          const lootboxesWithColor = sortedData.map(lootbox => ({
            ...lootbox,
            color: getRarityColor(lootbox.rarity)
          }));

          setLootboxes(lootboxesWithColor);
          setTasks(tasksData);
          setError(null);
        } catch (err) {
          console.error('Error loading data:', err);
          setError('Failed to load data');
        } finally {
          setIsLoading(false);
          setIsTasksLoading(false);
        }
      }
    };

    fetchData();
  }, [isUserLoading, isUserError]);

  // И используем loadLootboxes в handleClaimReward
  const handleClaimReward = async (taskId) => {
    try {
      setClaimingTaskId(taskId);
      const response = await LootboxTaskService.ClaimTaskReward(taskId);

      // Проверяем, является ли ответ успешным и преобразуем его в JSON
      let result;
      if (response.ok) {
        result = await response.json();

        if (result.success) {
          UiService.SuccessToast(result.message);
          // Перезагружаем и задачи, и лутбоксы после успешного получения награды
          await Promise.all([loadTasks(), loadLootboxes()]);
        } else {
          UiService.ErrorToast(result.message || 'Failed to claim reward');
        }
      } else {
        UiService.ErrorToast('Failed to claim reward: ' + (response.statusText || 'Unknown error'));
      }
    } catch (err) {
      console.error('Error claiming reward:', err);
      UiService.ErrorToast('Failed to claim reward');
    } finally {
      setClaimingTaskId(null);
    }
  };

  const loadingOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAD,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  // Function to determine color based on rarity
  const getRarityColor = (rarity) => {
    switch (rarity.toLowerCase()) {
      case 'common':
        return '#798579';
      case 'rare':
        return '#4169e1';
      case 'epic':
        return '#800080';
      case 'legendary':
        return '#b22222';
      default:
        return '#3a7ebf';
    }
  };

  // Обновленный компонент для задач с тремя состояниями
  const TaskItem = ({ task, lootboxes, onClaimReward, claimingTaskId }) => {
    // Находим соответствующий лутбокс по имени
    const rewardLootbox = lootboxes.find(lb => lb.name === task.rewardLootbox.name);

    // Определяем состояние задачи
    let buttonText = "In Progress";
    let buttonClass = "disabled";

    // Состояние для отображения хинта
    const [showHint, setShowHint] = useState(false);

    if (task.status === "completed") {
      buttonText = "Claim";
      buttonClass = "active";
    } else if (task.status === "claimed") {
      buttonText = "Claimed";
      buttonClass = "claimed";
    }

    return (
      <div className={`task-item ${task.status}`}>
        <div className="task-info">
          <div className="task-header">
            <div className="task-name">{task.name}</div>
            {task.hint && (
              <div className="hint-container">
                <div
                  className="hint-badge"
                  onClick={() => setShowHint(!showHint)}
                >
                  ?
                </div>
                {showHint && (
                  <div className="hint-popup">
                    {task.hint}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="task-description">{task.description}</div>
          <div className="progress-container">
            <div className="progress-bar">
              <div
                className="progress-fill"
                style={{ width: `${task.progressPercentage}%` }}
              ></div>
            </div>
            <div className="progress-text"> {Math.min(task.currentProgress, task.totalProgress)}/{task.totalProgress}</div>
          </div>
        </div>
        <div className="task-reward">
          <div className="reward-text">Reward:</div>
          {rewardLootbox && (
            <div className="reward-lootbox">
              <img
                src={rewardLootbox.imageUrl}
                alt={rewardLootbox.name}
                className="reward-lootbox-image"
              />
              <div className="reward-lootbox-name">
                {task.rewardLootbox.quantity > 1 ? `${task.rewardLootbox.quantity}x ` : ''}
                {rewardLootbox.name}
              </div>
            </div>
          )}
          <button
            className={`claim-button ${buttonClass}`}
            disabled={task.status !== "completed" || claimingTaskId === task.id}
            onClick={() => task.status === "completed" && onClaimReward(task.id)}
          >
            {claimingTaskId === task.id ? (
              <>
                <Lottie options={loadingOptions} height={18} width={18} speed={1.5} />
              </>
            ) : buttonText}
          </button>
        </div>
      </div>
    );
  };

  const getStatusPriority = (status) => {
    switch (status) {
      case 'completed': return 1;
      case 'in-progress': return 2;
      case 'claimed': return 3;
      default: return 4;
    }
  };

  if (isUserLoading || isLoading) {
    return (
      <div className="page">
        <Lottie
          options={loadingOptions}
          height={256}
          width={256}
          speed={1.5}
        />
      </div>
    );
  }

  if (isUserError || error) {
    return (
      <div className="page error-page">
        <div className="error-message">
          {error || 'An error occurred while loading data'}
        </div>
      </div>
    );
  }

  const sortedTasks = [...tasks].sort((a, b) => {
    return getStatusPriority(a.status) - getStatusPriority(b.status);
  });

  return (
    <div className="page lootboxes-page">
      <div className="lootboxes-header">
        <div className="lootboxes-title">Loot Boxes</div>
        <div className="lootboxes-description">
          Collect and open to earn rewards
        </div>
      </div>

      {lootboxes.length === 0 ? (
        <div className="no-lootboxes">
          You don't have any lootboxes yet. Complete tasks to earn them!
        </div>
      ) : (
        <div className="lootboxes-grid">
          {lootboxes.map(lootbox => (
            <div key={lootbox.id} className="lootbox-item">
              {lootbox.quantity > 0 && (
                <div className="lootbox-badge">
                  x{lootbox.quantity}
                </div>
              )}
              <div
                className="lootbox-image-container"
                style={{
                  boxShadow: lootbox.quantity > 0 ? `0 0 15px rgba(${hexToRgb(lootbox.color)}, 0.42)` : 'none'
                }}
              >
                <img
                  src={lootbox.imageUrl}
                  alt={lootbox.name}
                  className="lootbox-image"
                />
              </div>
              <div className="lootbox-info">
                <div className="lootbox-name">{lootbox.name}</div>
                <div className="lootbox-rarity">{lootbox.rarity}</div>
              </div>
              <button
                className={`lootbox-button ${lootbox.canOpen ? 'active' : (lootbox.quantity > 0 ? 'opening-soon' : 'disabled')}`}
                disabled={!lootbox.canOpen}
              >
                {lootbox.canOpen ? 'Open' : (lootbox.quantity > 0 ? 'Cannot Open Yet' : 'Unavailable')}
              </button>
            </div>
          ))}
        </div>
      )}

      {isTasksLoading || tasks.length > 0 ? (
        <div className="tasks-section">
          <div className="tasks-header">Tasks</div>
          <div className="tasks-list">
            {isTasksLoading ? (
              <div className="loading-container">
                <Lottie options={loadingOptions} height={128} width={128} speed={1.5} />
              </div>
            ) : (
              sortedTasks.map(task => (
                <TaskItem
                  key={task.id}
                  task={task}
                  lootboxes={lootboxes}
                  onClaimReward={handleClaimReward}
                  claimingTaskId={claimingTaskId}
                />
              ))
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};


function hexToRgb(hex) {
  // Remove # if present
  hex = hex.replace('#', '');

  // Convert to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `${r}, ${g}, ${b}`;
}

export default Lootboxes; 