import React, { useEffect, useState, useRef } from 'react';
import { UiService } from '../services/ui.service';
import { ActionApiService } from '../services/action.api.service';
import { toast, Bounce } from 'react-toastify';
import Lottie from 'react-lottie';
import tgstarAD from '../lotties/tgstar.json';
import loadingAD from '../lotties/loading.json';
import { InfoApiService } from '../services/info.api.service';

export function WithdrawStars({ starsBalance, shown, close, minHeight }) {
    const [activeStep, setActiveStep] = useState(1);
    const [sendAmount, setSendAmount] = useState(0);
    const [sendAmountValid, setSendAmountValid] = useState(false);
    const [sendAmountErrorMessage, setSendAmountErrorMessage] = useState(null);
    const [amountInputWidth, setAmountInputWidth] = useState(40);
    const [controlsDisabled, setControlsDisabled] = useState(false);
    const amount_input_content = useRef();


    const tgstarOptions = {
        loop: true,
        autoplay: true,
        animationData: tgstarAD,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };


    function closeAndReset() {
        setActiveStep(1);
        setSendAmount(0);
        setSendAmountValid(false);
        setSendAmountErrorMessage(null)
        close();
    }

    function nextStep() {
        setActiveStep(activeStep + 1);
    }

    useEffect(() => {
        if (shown) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [shown]);

    useEffect(() => {
        if (amount_input_content.current)
            setAmountInputWidth(amount_input_content.current.offsetWidth + 8);

        if (!sendAmount || sendAmount === '') {
            setSendAmountValid(false);
            return;
        }

        let sendAmountNumber = Number(removeLeadingZerosRegex(sendAmount));
        let isValid = true;
        let errorMessage = '';

        if (sendAmountNumber < 55) {
            isValid = false;
            errorMessage = "Minimum withdrawal amount is 55 bSTARS";
        } else if (starsBalance < sendAmountNumber) {
            isValid = false;
            errorMessage = "Insufficient bSTARS balance";
        }

        setSendAmountValid(isValid);
        setSendAmountErrorMessage(errorMessage);

    }, [sendAmount]);

    if (shown === false) {
        return null;
    }

    function confirmAndSend() {
        setControlsDisabled(true);
        // Здесь будет API запрос на вывод звезд
        InfoApiService.RequestWithdrawStars(sendAmount).then(result => {
            if (result.isSuccess === true) {
                toast.success('Withdrawal in process. May take up to 24 hours for security reasons. 2% fee, min. 5 Stars', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
                closeAndReset();
            }
            else {
                toast.error(result.message ?? 'Failed.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
            }
            setControlsDisabled(false);
        }).catch(() => {
            setControlsDisabled(false);
        });
    }

    function sendAmountChanged(sendAmount) {
        setSendAmount(sendAmount);
    }

    function removeLeadingZerosRegex(str) {
        if (!str) return '';
        return str.replace(/^0+(?=\d)/, '');
    }

    const loadingOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAD,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <div className={'modal_container visible'}>
            <div onClick={closeAndReset} className='modal_overlay_half'></div>
            <div className='bottom_stuck_modal half_stuck_modal modal_scrollable' style={{ minHeight: minHeight }}>
                <div className='bottom_stuck_modal_content pdside16'>
                    {activeStep > 1 && <div onClick={() => activeStep == 1 ? close() : setActiveStep(activeStep - 1)} className="close_btn mtl"><img src="/images/caret.png" /></div>}
                    {activeStep === 1 &&
                        <>
                            <div className='popup_title'>
                                Withdraw bSTARS
                            </div>
                            <div className={'amount_input_crisper ' + (sendAmountErrorMessage && sendAmountErrorMessage !== '' ? 'error' : '')}>
                                <span className="amount_input_content" ref={amount_input_content}>{!sendAmount || sendAmount === '' ? <span>&nbsp;&nbsp;&nbsp;&nbsp;</span> : sendAmount}</span>
                                <input inputMode="decimal" pattern="[0-9]*" autoFocus type="number" style={{ width: amountInputWidth }} className="default_input amount_input" value={sendAmount} onChange={event => sendAmountChanged(removeLeadingZerosRegex(event.target.value))} />
                                <span className='amount_input_suffix'>Stars</span>
                            </div>
                            {sendAmountErrorMessage && sendAmountErrorMessage !== '' &&
                                <div className='amount_input_error_message'>{sendAmountErrorMessage}</div>
                            }
                            <div className='from_balance_crisper'>
                                <Lottie
                                    options={{
                                        ...tgstarOptions,
                                        autoplay: false,
                                        loop: false
                                    }}
                                    height={24}
                                    width={24}
                                    isStopped={true}
                                    isPaused={true}
                                    initialSegment={[0, 1]}
                                    style={{margin: 'unset'}}
                                />
                                <div className='from_balance_asset_desc'>
                                    <div className='from_balance_asset_title'>From Balance</div>
                                    <div className='from_balance_asset_value'>{starsBalance} bSTARS</div>
                                </div>
                            </div>

                            <div className=""><div onClick={nextStep} style={{ marginTop: 12 }} className={"default_next_btn btn_red " + (sendAmountValid === true ? "" : "unclickable")}>Continue</div></div>
                        </>
                    }

                    {activeStep === 2 &&
                        <>
                            {controlsDisabled === true && <Lottie
                                options={loadingOptions}
                                height={200}
                                width={200}
                                speed={1.5}
                            />}
                            {controlsDisabled === false &&
                                <>
                                    <div className="asset_logo_crisper">
                                        <Lottie
                                            options={tgstarOptions}
                                            height={64}
                                            width={64}
                                            speed={0.75}
                                        />
                                    </div>
                                    <div className='confirm_send_title'>Confirm action</div>
                                    <div className='confirm_send_subtitle'>Withdraw bSTARS</div>
                                    <div className='confirm_send_warning'>
                                        A small fee will be charged for withdrawal
                                    </div>
                                </>
                            }

                            <div>
                                <div className="plain_badge">
                                    <div className='plain_badge_title'>
                                        Amount
                                    </div>
                                    <div className='plain_badge_value'>
                                        {sendAmount + ' bSTARS'}
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <div onClick={confirmAndSend} className={"default_next_btn btn_red " + (sendAmountValid === true && controlsDisabled === false ? "" : "unclickable")}>
                                    Withdraw bSTARS
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}