import { authFetch } from "./responseHandler";

export class LootboxApiService {
    static baseUrl = process.env.REACT_APP_API_URL;

    static async GetLootboxes() {
        const accessToken = localStorage.getItem('bb_token');
        if (!accessToken)
            return [];

        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const result = await authFetch(this.baseUrl + `Lootbox/GetLootboxes`, { headers });

        return await result.json();
    }

    // Метод для получения URL для вебхука (если понадобится в будущем)
    static GetLootboxesHookUrl() {
        return this.baseUrl + "Lootbox/GetLootboxes";
    }
} 