
import React, { useEffect, useState, useRef } from 'react';
import { TonHelper } from '../helpers/ton-helper';
import { UiService } from '../services/ui.service';
import { cuttedWallet } from "../components/cuttedWallet";
import { ActionApiService } from '../services/action.api.service';
import { toast, Bounce } from 'react-toastify';
import { LocalStorageHelper } from '../helpers/local-storage-helper';
import Lottie from 'react-lottie';
import loadingAD from '../lotties/loading.json';
import { formattedNumber } from './formattedNumber';
import { TonConnectLoodDepositCrisper } from './ton-connect-lood-deposit-crisper';
import { InfoApiService } from '../services/info.api.service';


export function WithdrawLoodWallet({ tonBalance, coinBalance, children, shown, close, minHeight }) {
    const [activeStep, setActiveStep] = useState(1);
    const [sendAmount, setSendAmount] = useState(0);
    const [sendAmountValid, setSendAmountValid] = useState(false);
    const [sendAmountErrorMessage, setSendAmountErrorMessage] = useState(null);
    const [amountInputWidth, setAmountInputWidth] = useState(40);
    const [controlsDisabled, setControlsDisabled] = useState(false);
    const amount_input_content = useRef();


    function closeAndReset() {
        setActiveStep(1);
        setSendAmount(0);
        setSendAmountValid(false);
        setSendAmountErrorMessage(null)

        close();
    }

    let tonBalanceNumber = Number(tonBalance.replace(/,/g, '.'));

    function nextStep() {
        setActiveStep(activeStep + 1);
    }

    useEffect(() => {
        if (shown) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [shown]);

    useEffect(() => {
        if (amount_input_content.current)
            setAmountInputWidth(amount_input_content.current.offsetWidth + 8);

        if (!sendAmount || sendAmount === '') {
            setSendAmountValid(false);
        }

        if (sendAmount && sendAmount != '' && coinBalance) {
            let sendAmountNumber = Number(removeLeadingZerosRegex(sendAmount.replace(/,/g, '.')));
            let isValid = true;
            let errorMessage = '';

            if (coinBalance.title === "TON") {
                if (tonBalanceNumber < sendAmountNumber) {
                    isValid = false;
                    errorMessage = "Insufficient balance";
                }
            }
            else {
                if (coinBalance.balance < sendAmountNumber) {
                    isValid = false;
                    errorMessage = "Insufficient balance";
                }
                else {
                    if (tonBalanceNumber < 0.1) {
                        isValid = false;
                        errorMessage = "Insufficient funds to pay 0.1 TON fee. (Recommended balance at least 0.11 TON)";
                    }
                }
            }

            setSendAmountValid(isValid);
            setSendAmountErrorMessage(errorMessage);
        }

    }, [sendAmount]);


    if (shown === false) {
        return null;
    }

    function confirmAndSend() {
        setControlsDisabled(true);
        InfoApiService.RequestWithdraw(sendAmount, coinBalance?.coinAddress).then(result => {
            if (result.isSuccess === true) {
                toast.success('Withdrawal in process. May take up to 24 hours – for security reasons!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
                closeAndReset();
            }
            else {
                toast.error(result.message ?? 'Failed.', {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce,
                });
            }

            setControlsDisabled(false);
        }).catch((error) => {
            setControlsDisabled(false);
        });
    }


    function sendAmountChanged(sendAmount) {
        setSendAmount(sendAmount);
    }

    function removeLeadingZerosRegex(str) {
        if (!str)
            return '';

        return str.replace(/^0+(?=\d)/, '');
    }

    const loadingOptions = {
        loop: true,
        autoplay: true,
        animationData: loadingAD,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };



    return (
        <div className={'modal_container visible'}>
            <div onClick={() => { closeAndReset() }} className='modal_overlay_half'></div>
            <div className='bottom_stuck_modal half_stuck_modal modal_scrollable' style={{ minHeight: minHeight }}>
                <div className='bottom_stuck_modal_content pdside16'>
                    {activeStep > 1 && <div onClick={() => activeStep == 1 ? close() : setActiveStep(activeStep - 1)} className="close_btn mtl"><img src="/images/caret.png" /></div>}
                    {activeStep === 1 &&
                        <>
                            <div className='popup_title'>
                               Request Withdrawal
                            </div>
                            <div className={'amount_input_crisper ' + (sendAmountErrorMessage && sendAmountErrorMessage !== '' ? 'error' : '')}>
                                <span className="amount_input_content" ref={amount_input_content}>{!sendAmount || sendAmount === '' ? <span>&nbsp;&nbsp;&nbsp;&nbsp;</span> : sendAmount}</span>
                                <input inputMode="decimal" pattern="[0-9]*" autoFocus type="number" style={{ width: amountInputWidth }} className="default_input  amount_input" value={sendAmount} onChange={event => sendAmountChanged(removeLeadingZerosRegex(event.target.value))} />
                                <span className='amount_input_suffix'>{coinBalance?.title}</span>
                            </div>
                            {sendAmountErrorMessage && sendAmountErrorMessage !== '' &&
                                <div className='amount_input_error_message'>{sendAmountErrorMessage}</div>
                            }
                            <div className='from_balance_crisper'>
                                <img src={coinBalance?.image ?? TonHelper.NoImageAsset()} className='from_balance_asset_image' />
                                <div className='from_balance_asset_desc'>
                                    <div className='from_balance_asset_title'>From Balance</div>
                                    <div className='from_balance_asset_value'>{coinBalance?.title === "TON" ? tonBalanceNumber + " TON" : coinBalance?.balance + " " + coinBalance?.title}</div>
                                </div>
                            </div>

                            <div className=""><div onClick={() => nextStep()} style={{ marginTop: 12 }} className={"default_next_btn btn_red " + (sendAmountValid === true ? "" : "unclickable")}>Continue</div></div>
                        </>
                    }

                    {activeStep === 2 &&
                        <>
                            {controlsDisabled === true && <Lottie
                                options={loadingOptions}
                                height={200}
                                width={200}
                                speed={1.5}
                            />}
                            {controlsDisabled === false && coinBalance?.image &&
                                <>
                                    <div className="asset_logo_crisper">
                                        <img src={coinBalance?.image} />
                                    </div>
                                    <div className='confirm_send_title'>Confirm action</div>
                                    <div className='confirm_send_subtitle'>Withdraw {coinBalance?.title}</div>
                                </>
                            }

                            <div>
                                <div className="plain_badge">
                                    <div className='plain_badge_title'>
                                        Amount
                                    </div>
                                    <div className='plain_badge_value'>
                                        {sendAmount + ' ' + coinBalance?.title}
                                    </div>
                                </div>
                            </div>
                            <div className="">
                                <div onClick={() => confirmAndSend()} className={"default_next_btn btn_red " + (sendAmountValid === true && controlsDisabled === false ? "" : "unclickable")}>
                                    Request Withdrawal
                                </div>
                            </div>
                        </>
                    }



                </div>

            </div>
        </div >
    );


}
