
import { authFetch } from "./responseHandler";

export class LootboxTaskService {
    static baseUrl = process.env.REACT_APP_API_URL;

    static async GetUserTasks() {
        const accessToken = localStorage.getItem('bb_token');
        if (!accessToken)
            return [];

        const headers = { 'Authorization': 'Bearer ' + accessToken };
        const result = await authFetch(this.baseUrl + `LootboxTask/GetUserTasks`, { headers });

        return await result.json();
    }


    static async ClaimTaskReward(taskId) {
      const accessToken = localStorage.getItem('bb_token');
      if (!accessToken)
          return;

      const headers = { 'Authorization': 'Bearer ' + accessToken, 'content-type': 'application/json' };

      let request = await fetch(this.baseUrl + `LootboxTask/ClaimTaskReward?taskId=${taskId}`, {
          method: "POST",
          headers: headers
      });

      return request;
  }

} 